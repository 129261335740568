@charset "UTF-8";

@font-face {
  font-family: "trucktrailer";
  src:url("fonts/trucktrailer.eot");
  src:url("fonts/trucktrailer.eot?#iefix") format("embedded-opentype"),
    url("fonts/trucktrailer.woff") format("woff"),
    url("fonts/trucktrailer.ttf") format("truetype"),
    url("fonts/trucktrailer.svg#trucktrailer") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "trucktrailer" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "trucktrailer" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-fulltruck:before {
  content: "\61";
}
.icon-trailer:before {
  content: "\63";
}
