.dx-calendar {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    position: relative;
    display: block;
}
.dx-calendar.dx-calendar-with-footer .dx-calendar-footer {
    position: absolute;
    bottom: 0;
}
.dx-calendar-views-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}
.dx-calendar-navigator {
    position: relative;
    text-align: center;
    width: 100%;
}
.dx-calendar-navigator .dx-button {
    position: absolute;
    display: inline-block;
}
.dx-calendar-navigator .dx-button.dx-calendar-disabled-navigator-link {
    visibility: hidden;
}
.dx-calendar-navigator .dx-calendar-caption-button {
    text-decoration: none;
}
.dx-calendar-body {
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}
.dx-calendar-body .dx-widget {
    position: absolute;
    width: 100%;
    height: 100%;
}
.dx-calendar-body table {
    width: 100%;
    height: 100%;
    position: absolute;
    direction: ltr;
    table-layout: fixed;
}
.dx-calendar-body td {
    cursor: pointer;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}
.dx-calendar-body thead th {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.dx-calendar-cell {
    white-space: normal;
}
.dx-calendar-contoured-date {
    outline-offset: -1px;
}
.dx-rtl.dx-calendar .dx-calendar-body table {
    left: 0px;
}
.dx-rtl.dx-calendar .dx-calendar-body .dx-widget {
    direction: ltr;
}
.dx-state-disabled .dx-calendar .dx-calendar-navigator-previous-month,
.dx-state-disabled.dx-calendar .dx-calendar-navigator-previous-month,
.dx-state-disabled .dx-calendar .dx-calendar-navigator-next-month,
.dx-state-disabled.dx-calendar .dx-calendar-navigator-next-month {
    cursor: default;
}
.dx-state-disabled .dx-calendar-body table th,
.dx-state-disabled .dx-calendar-body table td {
    cursor: default;
}
.dx-calendar {
    width: 282px;
    min-width: 282px;
    height: 268.6px;
    min-height: 268.6px;
    background-color: #fff;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: 0;
    border: 1px solid transparent;
}
.dx-calendar.dx-calendar-with-footer {
    height: 323.6px;
    min-height: 293.6px;
}
.dx-calendar.dx-calendar-with-footer .dx-calendar-body {
    bottom: 55px;
}
.dx-calendar.dx-calendar-with-footer .dx-calendar-footer {
    text-align: center;
    height: 45px;
    width: 100%;
}
.dx-calendar.dx-calendar-with-footer .dx-calendar-footer .dx-button {
    background: none;
}
.dx-calendar.dx-calendar-with-footer
    .dx-calendar-footer
    .dx-button.dx-state-active {
    background-color: #d4d4d4;
}
.dx-calendar-navigator {
    position: relative;
    text-align: center;
    width: 100%;
}
.dx-calendar-navigator .dx-button {
    position: absolute;
    display: inline-block;
}
.dx-calendar-navigator .dx-button.dx-calendar-disabled-navigator-link {
    visibility: hidden;
}
.dx-calendar-navigator .dx-calendar-caption-button {
    text-decoration: none;
}
.dx-calendar-navigator {
    line-height: 1.6;
    height: 36px;
    //NOTE override
    height: 26px !important;

    .dx-button-text {
        font-size: 12px !important;
        line-break: unset !important;
    }

    display: table;
    border-collapse: collapse;
}
.dx-calendar-navigator .dx-button {
    height: 100%;
    border-radius: 0;
    display: table-cell;
    border-color: #ddd;
}
.dx-calendar-navigator .dx-button .dx-icon {
    font-size: 16px;
}
.dx-calendar-navigator .dx-button.dx-calendar-disabled-navigator-link {
    border-collapse: collapse;
    visibility: visible;
    opacity: 1;
}
.dx-calendar-navigator
    .dx-button.dx-calendar-disabled-navigator-link
    .dx-button-content {
    opacity: 0.5;
}
.dx-calendar-navigator
    .dx-button.dx-state-active:not(.dx-calendar-disabled-navigator-link) {
    z-index: 1;
}
.dx-calendar-navigator
    .dx-button.dx-state-hover:not(.dx-calendar-disabled-navigator-link) {
    z-index: 1;
    border-color: #ddd;
}
.dx-calendar-navigator .dx-calendar-caption-button {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.2;
    text-transform: uppercase;
    right: 32px;
    left: 32px;
    right: 76px; //NOTE overridden
    left: 76px;
}
.dx-calendar-navigator .dx-calendar-caption-button.dx-button.dx-state-active {
    background-color: #d4d4d4;
}
.dx-calendar-navigator
    .dx-calendar-caption-button.dx-button
    .dx-button-content {
    //ANCHOR override
    padding: 2px 15px 4px;
    padding: 0;
    color: #337ab7;
}
.dx-calendar-navigator-previous-month {
    width: 32px;
    background: none;
}
.dx-calendar-navigator-previous-month.dx-button {
    margin: 0 2px;
}
.dx-calendar-navigator-previous-month .dx-button-content {
    padding: 0;
}
.dx-calendar-navigator-previous-month.dx-state-hover {
    z-index: 1;
}
.dx-calendar-navigator-previous-month,
.dx-calendar-navigator-next-view {
    width: 32px;
    background: none;
}
.dx-calendar-navigator-previous-month.dx-button,
.dx-calendar-navigator-next-view.dx-button {
    margin: 0px 1px;
}
.dx-calendar-navigator-previous-month .dx-button-content,
.dx-calendar-navigator-next-view .dx-button-content {
    padding: 0;
}
.dx-calendar-navigator-previous-view,
.dx-calendar-navigator-previous-month {
    left: 0px;
}
.dx-calendar-navigator-previous-view.dx-button,
.dx-calendar-navigator-previous-month.dx-button {
    border-radius: 4px 0 0 4px;
}
.dx-calendar-navigator-previous-view.dx-button .dx-icon,
.dx-calendar-navigator-previous-month.dx-button .dx-icon {
    color: #337ab7;
}
.dx-calendar-navigator-next-view,
.dx-calendar-navigator-next-month {
    right: 0px;
}
.dx-calendar-navigator-next-view.dx-button,
.dx-calendar-navigator-next-month.dx-button {
    border-radius: 0 4px 4px 0;
}
.dx-calendar-navigator-next-view.dx-button .dx-icon,
.dx-calendar-navigator-next-month.dx-button .dx-icon {
    color: #337ab7;
}
.dx-calendar-body {
    top: 52px;
}
.dx-calendar-body thead {
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.2;
}
.dx-calendar-body thead tr {
    height: 25px;
    padding-bottom: 10px;
}
.dx-calendar-body thead tr th {
    -webkit-box-shadow: inset 0px -1px 0px #ddd;
    box-shadow: inset 0px -1px 0px #ddd;
    color: #999999;
}
.dx-calendar-body table {
    border-spacing: 0px;
}
.dx-calendar-body table th {
    color: #999999;
    text-align: center;
    font-size: 12px;
    padding: 1px 0 6px 0;
}
.dx-calendar-cell {
    text-align: center;
    padding: 1px 8px 2px;
    color: #333;
    font-size: 15px;
    border: 1px double transparent;
    width: 39px;
    border-radius: 4px;
}
.dx-calendar-cell.dx-calendar-today {
    font-weight: bold;
}
.dx-calendar-cell.dx-state-hover {
    -webkit-box-shadow: inset 0px -1px 0px 1000px #f5f5f5;
    box-shadow: inset 0px -1px 0px 1000px #f5f5f5;
    color: #333;
}
.dx-calendar-cell.dx-calendar-other-view,
.dx-calendar-cell.dx-calendar-empty-cell {
    color: #b0b0b0;
}
.dx-calendar-cell.dx-calendar-other-view.dx-state-hover,
.dx-calendar-cell.dx-calendar-empty-cell.dx-state-hover,
.dx-calendar-cell.dx-calendar-other-view.dx-state-active,
.dx-calendar-cell.dx-calendar-empty-cell.dx-state-active {
    color: #b0b0b0;
}
.dx-calendar-cell.dx-calendar-empty-cell {
    cursor: default;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAm0lEQVRIx7XVwQnAIAwF0ExSdBF1a6Er9dIRqsVAazWJmh4+iuBT4YMQ4w4pWxk1clt5YlOOFKeAumJZXAgKOKIBb6yBv9AansU/aAsexZtoD5biXZSCOZxEObiHs6gErnERKoURP0uCZM9IpRB2WvDz+eIqzvRUhMNkT1mcQz1xsKfwWZTFV1ASX0W7uAbaxPOCUUBr3MBfn+kF3CNLT2/yky4AAAAASUVORK5CYII=)
        center center no-repeat;
}
.dx-calendar-cell.dx-calendar-empty-cell.dx-state-hover {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.dx-calendar-cell.dx-state-active:not(.dx-calendar-empty-cell):not(.dx-calendar-selected-date) {
    -webkit-box-shadow: inset 0px -1px 0px 1000px rgba(96, 96, 96, 0.2);
    box-shadow: inset 0px -1px 0px 1000px rgba(96, 96, 96, 0.2);
}
.dx-calendar-cell.dx-calendar-contoured-date {
    -webkit-box-shadow: inset 0px 0px 0px 1px #bebebe;
    box-shadow: inset 0px 0px 0px 1px #bebebe;
}
.dx-calendar-cell.dx-calendar-selected-date,
.dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today {
    color: #fff;
    -webkit-box-shadow: inset 0px 0px 0px 1000px #337ab7;
    box-shadow: inset 0px 0px 0px 1000px #337ab7;
    font-weight: normal;
}
.dx-calendar-cell.dx-calendar-selected-date.dx-calendar-contoured-date,
.dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today.dx-calendar-contoured-date {
    -webkit-box-shadow: inset 0px 0px 0px 1px #bebebe,
        inset 0px 0px 0px 1000px #337ab7;
    box-shadow: inset 0px 0px 0px 1px #bebebe, inset 0px 0px 0px 1000px #337ab7;
}
.dx-invalid.dx-calendar {
    border-color: rgba(217, 83, 79, 0.4);
}
.dx-invalid.dx-calendar.dx-state-focused {
    border-color: #d9534f;
}
.dx-popup-wrapper .dx-calendar .dx-calendar-caption-button {
    margin: 0;
}
