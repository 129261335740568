$color-error-dark: #721c24;

@mixin scrollbars {
    ::-webkit-scrollbar {
        width: 12px;
        height: 12px;
        background-color:$pdi-dark-gray;
    }

    ::-webkit-scrollbar-track {
        background-color:$pdi-product-background-gray;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: $pdi-cool-gray;
    }
}

@mixin forms-custom-controls {
    textarea {
        border: 1px solid $pdi-cool-gray !important;
    }

    .col-form-label,
    .control-label {
        font-size: 14px;
        line-height: 1.42857143;
        color: $pdi-soft-black;
    }

    .form-custom-control {
        @include input-bordered;

        display: block;
        height: 34px;
        font-size: 14px !important;
        line-height: 1.42857143;
        color: $pdi-soft-black;
        background-color: $pdi-white;
        background-image: none;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

        &.control-look-input {
            border: 0 hidden;
            box-shadow: none;
            height: unset;
        }

        &.form-control-disabled {
            background-color: $pdi-cool-gray;
            border-radius: 0;
        }
    }

    select {
        cursor: pointer;
        font-size: 14px !important;
        border-radius: 0;
        border: 0 hidden;
        box-shadow: none;

        &.select-bordered {
            @include input-bordered;
        }
    }

    .checkbox-inline {
        height: 34px;
    }
}

@mixin input-bordered {
    border: 1px solid $pdi-cool-gray;
    border-radius: 4px;
    padding: 6px 12px;
    width: 100%;
}
