@charset "UTF-8";

@font-face {
  font-family: "status-icons";
  src:url("fonts/status-icons.eot");
  src:url("fonts/status-icons.eot?#iefix") format("embedded-opentype"),
    url("fonts/status-icons.woff") format("woff"),
    url("fonts/status-icons.ttf") format("truetype"),
    url("fonts/status-icons.svg#status-icons") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "status-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="status-icon-"]:before,
[class*=" status-icon-"]:before {
  font-family: "status-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.status-icon-order-status-open:before {
  content: "\61";
}
.status-icon-order-status-driver-on-way:before {
  content: "\62";
}
.status-icon-order-status-driver-arrived:before {
  content: "\63";
}
.status-icon-order-status-order-in-progress:before {
  content: "\64";
}
.status-icon-order-status-done:before {
  content: "\65";
}
.status-icon-satelite:before {
  content: "\66";
}
.status-icon-altitude:before {
  content: "\67";
}
.status-icon-parking:before {
  content: "\68";
}
.status-icon-load-point:before {
  content: "\69";
}
.status-icon-delay:before {
  content: "\6a";
}
.status-icon-closed-by-dispatcher:before {
  content: "\6b";
}
.status-icon-closed-by-driver:before {
  content: "\6c";
}
.status-icon-thumbs-up:before {
  content: "\6d";
}
.status-icon-thumbs-down:before {
  content: "\6e";
}
.status-icon-trip-not-open:before {
  content: "\6f";
}
.status-icon-trip-in-progress:before {
  content: "\70";
}
.status-icon-trip-done:before {
  content: "\71";
}
.status-icon-trip-canceled:before {
  content: "\72";
}
.status-icon-warning-quantity-change:before {
  content: "\73";
}
