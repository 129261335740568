@import "styles.ui.scss";
@import "~ngx-smart-modal/ngx-smart-modal.css";
// devexteme overrides

@import "styles.calendar.scss";
@import '~@pdi/platform-common-styles/styles/scss/partials/colors';

/* copy from legacy Style.css */
/*      Colour Scheme
    light green: #96DBC7 #8FDBC9
    dark green: #41BB99 #1CBB9B
    selected row background light green: #C1EDE0 
*/

/* Media Query container widths and form-group inner divs padding*/
.form-group>div {
  padding-right: 6px;
  padding-left: 6px;
}

/*.form-group {
      margin-bottom:4px;
  }*/
@media (min-width: 768px) {
  .container {
    width: inherit;
 }

  .form-horizontal .control-label {
    text-align: left;
  }
}
@media (min-width: 1199px) and (max-width: 1330px) {
  .nav>li>a{
  padding-left: 7px;
  padding-right: 7px;}
  }
  @media (min-width: 768px) and (max-width: 790px) {
  .nav>li>a{
  padding-left: 7px;
  padding-right: 7px;}
  }

@media (min-width: 700px) {
  .form-group>div {
    padding-right: 2px;
    padding-left: 2px;
  }
}

@media (min-width: 800px) {
  .form-group>div {
    padding-right: 3px;
    padding-left: 3px;
  }
}

@media (min-width: 900px) {
  .form-group>div {
    padding-right: 4px;
    padding-left: 4px;
  }
}

@media (min-width: 1000px) {
  .form-group>div {
    padding-right: 5px;
    padding-left: 5px;
  }
}

@media (min-width: 1100px) {
  .form-group>div {
    padding-right: 6px;
    padding-left: 6px;
  }
}

@media (min-width: 1200px) {
  .second-column {
    padding-left: 30px;
  }

  .form-group>div {
    padding-right: 7px;
    padding-left: 7px;
  }
}

@media (min-width: 1300px) {
  .form-group>div {
    padding-right: 8px;
    padding-left: 8px;
  }
}

@media (min-width: 1400px) {
  .form-group>div {
    padding-right: 9px;
    padding-left: 9px;
  }
}

@media (min-width: 1500px) {
  .form-group>div {
    padding-right: 10px;
    padding-left: 10px;
  }
}

/*Layout CSS*/
body {
  margin: 0px;
  padding: 0px;
}

.containerBox {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  min-width: 200px;
}

.spin-icon {
  animation: spin 5s infinite linear;
  -webkit-animation: spin2 5s infinite linear;
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }

  to {
    transform: scale(1) rotate(360deg);
  }
}

@keyframes spin2 {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.contentBox {
  position: absolute;
  width: 100%;
  top: 80px;
  bottom: 32px;
}

.contentBoxLeft {
  position: absolute;
  height: 100%;
}

.contentBoxRight {
  position: absolute;
  top: 1rem;
  bottom: 65px;
}

.contentBoxLeft,
.contentBoxRight {
  overflow-y: auto;
  overflow-x: hidden;
}

.contentBoxButtons {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: $pdi-white;
}

.contentBoxButtons button,
button {
  padding: 0;
  border-width: 0;
}



.right75 {
  width: 75%;
  left: 25%;
}

.center100 {
  width: 100%;
}

.control-look {
  position: relative;
  height: 8px;
  width: 100%;
  bottom: 10px;
}

.control-look-input {
  border: 0 hidden;
  box-shadow: none;
}

div#MainPage.alerts {
  position: fixed;
  width: 300px;
  left: 10px;
  bottom: 30px;
  z-index: 1036;
}

div#MainPage.alerts>.alert-dismissable {
  position: relative;
  z-index: 1100;
  min-width: 250px;
}

.modal-product-categories {
  max-height: 400px;
  overflow: auto;
}

/*=========================Bootstrap styles=================================*/
/*Bootstrap Navbar Styles*/
.navbar-fixed-bottom {
  margin-bottom: 17px;
}

/*Bootstrap Buttons Styles*/
.btn {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}

.form-control {
  border-radius: 0;
}

.popover .popover-title {
  font-weight: bold;
  color: $pdi-indigo;
  border: 2px solid $pdi-indigo;
}

.popover .popover-content {
  border: 2px solid $pdi-indigo;
  border-top: 0;
}

.popover-content table tbody tr {
  padding-bottom: 4px;
}

.popover-content table tbody tr td {
  min-width: 80px;
}

/* Bootstrap checkbox style */
.checkbox-form-group {
  line-height: 34px;
  text-align: right;
}

.form-group-inline {
  min-height: 42px;
}

/*Bootstrap tooltip*/
.tooltip-inner {
  white-space: pre-wrap;
}

/*========================= End Bootstrap styles ===================================*/
/*========================= Footer Styles ========================================*/

/* miscellaneous styles */
a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
}

#TotalCount .badge {
  font-size: 24px;
  border-radius: 30px;
  line-height: inherit;
}

.top-buffer {
  margin-top: 10px;
}

button[disabled] {
  cursor: not-allowed;
}

#filterOptions .heading-container {
  height: 38px;
  margin: 0px 0px 14px 10px;
  padding: 5px 0px 5px 0px;
  background-color: $pdi-product-background-gray;
  color: $pdi-granite;
  cursor: pointer;
  font-size: 14px;
  border: 1px solid $pdi-cool-gray;
}

#filterOptions .heading-container .heading-image {
  float: left;
  margin-top: -10px;
  margin-left: -10px;
}

#filterOptions .heading-text {
  padding: 10px 0px 10px 10px;
}

#filterOptions .content {
  height: 200px;
  overflow-y: scroll;
  margin-bottom: 40px;
}

#FilterOptionsForm .filter-buttons {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $pdi-white;
}

.collapsible-header-container {
  position: relative;
  left: 0;
  top: 0;
  height: 20px;
  cursor: pointer;
}

.collapsible-header-left {
  position: relative;
  top: 0;
  left: 0;
  height: 2px;
}

.collapsible-header-content {
  text-align: center;
  font-weight: bold;
  background-color: $pdi-white;
  position: relative;
  top: -9px;
  padding-left: 15px;
  padding-right: 15px;
  color: $pdi-indigo;
} 

.collapsible-header-right {
  position: relative;
  top: 0;
  left: 0;
  height: 2px;
}

.collapsible-header-green,.collapsible-header-yellow {
  border-bottom:2px solid $pdi-indigo;
}

.collapsible-header-icon {
  position: absolute;
  top: -8px;
  left: 22px;
  height: 20px;
  width: 20px;
  background-color: $pdi-white
}

.collapsible-header-icon-green-down, .collapsible-header-icon-yellow-down {
  background-image: url("assets/Content/Images/Icons/expand.svg");
}

.collapsible-header-icon-green-up, .collapsible-header-icon-yellow-up{
  background-image: url("assets/Content/Images/Icons/collapse.svg");
}

.left-arrow {
  margin-top: 120px;
}

.right-arrow {
  margin-top: 80px;
}

.selected {
  background-color:rgba($pdi-sunshine, 0.349);
}

/*Begin - Configuration Settings screen Styles */

#configsetings .contentBoxRight input[type="checkbox"],
.import-config-content input[type="checkbox"] {
  height: 20px;
  width: 20px;
}

/* Drag and Drop related css*/


.dragover {
  border: 5px dashed $pdi-indigo;
}

/* CSS for Import Configuration Screen Transition */
.import-config {
  text-align: center;
  transition: width linear 2s;
  position: absolute;
  top: 0;
  bottom: 65px;
  overflow-x: hidden;
  overflow-y: auto;
}

.import-config-content {
  position: absolute;
  top: 1rem;
  bottom: 65px;
  overflow-x: hidden;
  overflow-y: auto;
}

/*#transPacConfigurationForm .form-control {display: inline; width:80%;}*/
/* End - Configuration Settings screen Styles */

/* Begin - Vehicles screen Styles */


#FilterOptionsForm .form-group {
  margin-bottom: 0px;
}

/* End - Vehicles screen Styles */

/* Begin - Modal related css */
.modal-dialog input[type="checkbox"] {
  height: 15px;
  width: 15px;
  float: left;
  margin-left: 10px;
}

.modal-header {
  background-color: $pdi-indigo;
  color: $pdi-white;
  border-bottom: none;
}

.modal-content {
  border: 3px solid $pdi-indigo;
}

.modal-content .nav-tabs.nav-justified>li {
  width: auto;
}


/* End - Modal related css */

/*begin - ui sortable related css*/


/*end - ui sortable related css*/
/*begin  - ui select related css*/
.ui-select-bootstrap .ui-select-choices-row.active>a,
.ui-select-bootstrap .ui-select-choices-row>a {
  background-color: $pdi-white;
  color: $pdi-soft-black;
}

.ui-select-multiple.ui-select-bootstrap {
  background-color: $pdi-indigo;
}

.ui-select-bootstrap>.ui-select-choices,
.ui-select-bootstrap>.ui-select-no-choice {
  width: unset;
}

/*end - ui select related css*/
/* Validation Styles */
input.ng-invalid.submitted+div {
  box-shadow: 0 1px 0 1px $pdi-alert;
}

select.ng-invalid.submitted+div {
  box-shadow: 0 1px 0 1px $pdi-alert;
}

input[type=text].ng-invalid.submitted,
textarea.ng-invalid.submitted,
input[type=password].ng-invalid.submitted,
input[type=email].ng-invalid.submitted {
  border: 1px solid $pdi-alert;
}

.error-message {
  color: $pdi-alert;
}

/*Devexpress related CSS Styles*/
/*Regular Datagrid CSS Styles*/
.gridDiv {
  border: 1px solid $pdi-cool-gray;
}

/*Datagrid header row style*/
.dx-header-row {
  background-color: $pdi-product-background-gray !important;
  color: $pdi-granite !important;
  font-weight: 600;
}

/*Datagrid selected row style*/
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-lines)>td,
.dx-datagrid-rowsview .dx-selection.dx-row:hover:not(.dx-row-lines)>td {
  border-top: 1px solid $pdi-cool-gray;
  border-bottom: 1px solid $pdi-cool-gray;
}

.dx-datagrid-rowsview .dx-selection.dx-row>td,
.dx-datagrid-rowsview .dx-selection.dx-row:hover>td {
  background-color: rgba($pdi-sunshine, 0.349);
  border-top: 1px solid $pdi-cool-gray;
  border-bottom: 1px solid $pdi-cool-gray;
  color: $pdi-granite;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td
{
  border-bottom: 1px solid $pdi-cool-gray !important;
}
/*Datagrid alternate row style*/
.dx-datagrid-rowsview .dx-row:nth-child(odd) {
  background-color: $pdi-white !important;
  color: $pdi-granite !important;
}

/*Datagrid Filter row cell border*/
.dx-datagrid-table .dx-row .dx-editor-cell .dx-texteditor {
  border: 4px solid $pdi-granite;
  height: 30px;
}
.dx-datagrid-borders .dx-datagrid-rowsview, .dx-datagrid-headers + .dx-datagrid-rowsview, .dx-datagrid-rowsview.dx-datagrid-after-headers
{
  border-top: none;
}
.dx-datagrid-borders > .dx-datagrid-headers, .dx-datagrid-borders > .dx-datagrid-rowsview, .dx-datagrid-borders > .dx-datagrid-total-footer
{
  border-right: 1px solid $pdi-cool-gray !important;
  border-left: 1px solid $pdi-cool-gray !important;
}
.dx-datagrid-borders > .dx-datagrid-rowsview, .dx-datagrid-borders > .dx-datagrid-total-footer
{
  border-bottom: 1px solid $pdi-cool-gray !important;
}
/*Datagrid header panel, which is used for insert button*/
.dx-datagrid-header-panel {
  background-color: $pdi-product-background-gray !important;
  color: $pdi-granite !important;
  font-weight: 600;
  border-bottom: 1px solid $pdi-cool-gray;
  text-align: center;
  height: 36px;
}

/*Datagrid header panel, which is used for attribute add button*/
.dx-datagrid-header-panel-2 {
  background-color: $pdi-product-background-gray !important;
  color: $pdi-granite !important;
  font-weight: 600;
  border-bottom: 1px solid $pdi-cool-gray;
  text-align: center;
  height: 58px;
}
/*DataGrid header dx add icon changed to pdi icon*/
.dx-icon-plus, .dx-datagrid-header-panel .dx-icon-edit-button-addrow {
  font-family: 'pdi-icon' !important;
}
.dx-icon-plus:before, .dx-datagrid-header-panel .dx-icon-edit-button-addrow:before {
  content: "\e902" !important;
}
/*Edit Datagrid CSS Styles*/
.editGridDiv {
  border: 1px solid $pdi-cool-gray;
}

.editGridDiv .dx-header-row {
  background-color: rgba($pdi-sunshine, 0.349);
  color: $pdi-granite;
}

.editGridDiv .dx-datagrid-rowsview .dx-selection>td,
.editGridDiv .dx-datagrid-rowsview .dx-selection.dx-row:hover>td {
  background-color: rgba($pdi-sunshine, 0.349);
  border-top: 1px solid $pdi-cool-gray;
  border-bottom: 1px solid $pdi-cool-gray;
  color: $pdi-granite;
}

.editGridDiv .dx-datagrid-rowsview .dx-row:nth-child(odd) {
  background-color: $pdi-white !important;
  color: $pdi-granite !important;
}

/*New Datagrid CSS Styles*/
.newGridDiv {
  border: 1px solid $pdi-cool-gray;
}

.newGridDiv .dx-header-row {
  background-color: rgba($pdi-sunshine, 0.349);
  color: $pdi-granite;
}

.newGridDiv .dx-datagrid-rowsview .dx-selection>td,
.newGridDiv .dx-datagrid-rowsview .dx-selection.dx-row:hover>td {
  background-color: rgba($pdi-sunshine, 0.349);
  border-top: 1px solid $pdi-cool-gray;
  border-bottom: 1px solid $pdi-cool-gray;
  color: $pdi-granite;
}

.newGridDiv .dx-datagrid-rowsview .dx-row:nth-child(odd) {
  background-color: $pdi-white !important;
  color: $pdi-granite !important;
}


.grid-cell-align-left {
  text-align: left !important;
}

/* Devexpress related CSS */

/*Miscellaneous*/
/*image size in input group for calendar*/
.input-group-btn>.btn>img {
  height: 18px;
  width: 18px;
}

/* active inactive driver icons*/
.status-icon-closed-by-driver.Active {
  color: $pdi-ocean;
  font-size: 30px;
}

.status-icon-closed-by-driver.InActive {
  color: $pdi-dark-gray;
  font-size: 30px;
}

.dx-overlay-content {
  z-index: 1052 !important;
}

.dx-overlay-wrapper {
  z-index: 1052 !important;
}

/* end copy from legacy Style.css */


.can-click {
  cursor: pointer;
}

.dx-checkbox-icon {
  width: 20px;
  height: 20px;
}
.dx-datagrid-headers, .dx-datagrid
{
  background-color: $pdi-white !important;
  color: $pdi-granite !important;
}
.dx-datagrid-headers
{
  border-bottom: 1px solid $pdi-cool-gray !important;
}
.dx-datagrid-borders > .dx-datagrid-filter-panel, .dx-datagrid-borders > .dx-datagrid-headers
{
  border-top: 1px solid $pdi-cool-gray !important;
}
.dx-gridbase-container {
  height: inherit !important;
  @include scrollbars;
  .dx-datagrid-content.dx-datagrid-scroll-container {
    margin-right: 0px !important;
  }
  .dx-row.dx-column-lines.dx-datagrid-filter-row {
    background-color: $pdi-white !important;
  }
  .dx-datagrid-rowsview {
    .dx-row {
      cursor: pointer !important;
      &.dx-row-client-deleted {
        text-decoration: line-through;
        font-style: italic;
        color: $color-error-dark;
      }
    }
  }
  .dx-datagrid-focus-overlay {
    border: 0 !important;
  }
  .dx-datagrid-table .dx-row .dx-editor-cell .dx-texteditor {
    height: 30px !important;
  }
  .dx-datagrid-table .dx-row > td {
    vertical-align: top !important;
  }
  .dx-menu-item-content {
    padding: 4px 5px 4px 5px !important;
  }
  .dx-checkbox-icon {
    // (view ##multiple-selection-column)
    width: 20px;
    height: 20px;
    margin-top: 5px;
  }
  .dx-command-select {
    width: 40px;
    min-width: 40px;
  }
  .dx-texteditor {
    height: unset !important;
  }
  .dx-texteditor.dx-editor-outlined {
    border-radius: 0 !important;
  }
  .dx-datagrid-filter-row .dx-menu {
    top: 2px !important;
    margin-left: 0px;
  }
  .dx-dropdowneditor-input-wrapper {
    margin-right: unset !important;
    .dx-texteditor-input {
      padding: 3px !important;
    }
    .dx-dropdowneditor-icon {
      position: absolute !important;
      top: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      width: 2.5em !important;
    }
  }
  .dx-datagrid-pager {
    .dx-light-pages {
      display: inline !important;
      .dx-page-index {
        display: inline-block !important;
        width: 4rem !important;
        .dx-texteditor-input {
          padding: 3px !important;
        }
      }
    }
  }
  .dx-pager .dx-page-sizes .dx-selection, .dx-pager .dx-pages .dx-selection
  {
    color: $pdi-white !important;
    background-color: $pdi-ocean !important;
    font-weight: 600;
  }
  .dx-datagrid-header-panel .dx-button-mode-contained
  {
  border: none !important;
  }
}
.dx-sort-up,
.dx-sort-down {
  filter: brightness(1.75) !important;
}
.dx-list-item {
  padding: 0 !important;
  border-top: 0 !important;
  margin-right: 1em !important;
}
.dx-list-item-content {
  padding: 2px 2px 2px !important;
  font-family: "Hind Siliguri","sans-serif";
}
.dx-context-menu-container-border {
  border: 0 !important;
}
.dx-selectbox-popup-wrapper,
.dx-popover-wrapper {
  @include scrollbars;
  .dx-popup-normal {
    overflow: auto;
  }
}
.dx-overlay-content.dx-popup-normal {
  border: 2px solid $pdi-indigo;
  .edit-button {
    padding: 4px 13px 9px 12px;
    margin: 0px;
  }
  .dx-item.dx-toolbar-item.dx-toolbar-label {
    padding: 0 10px;
    font-size: 20px;
  }
  .dx-popup-title {
    padding: 0;
    background-color: $pdi-indigo;
    color: $pdi-white;
    border-radius: 5px 5px 0px 0px;
    height: 40px !important;
    text-align: center;
    .dx-toolbar-label {
      padding: 0;
      max-width: unset;
      font-size: larger;
    }
  }
  .dx-popup-content {
    margin: 0;
    font-size: large;
    overflow: auto;
  }
  .dx-closebutton {
    padding: 3px 14px !important;
  }
}
.dx-datebox-calendar {
  .dx-texteditor-container {
    position: inherit !important;
  }
}
.dx-loadpanel-wrapper {
  z-index: 1501 !important;
}
.dx-overlay-modal {
  .dx-loadindicator {
    width: 80px !important;
    height: 80px !important;
  }
}
label {
  font-weight: unset;
}
.dx-state-focused {
  outline-color: transparent !important;
}
.dx-state-active {
  background-color: transparent !important;
}
.dx-state-hover {
  background-color: transparent !important;
}
.dx-tabs {
  background-color: transparent;
  padding: 0;
    .dx-tab {
    color: $pdi-cool-gray;
    background-image: none;
    font-size: 15px;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    border-radius: 0;
    border: 0;
    border-right: 1px solid $pdi-cool-gray;
    border-bottom: 6px solid $pdi-cool-gray;
        &.dx-tab-selected {
      color: $pdi-granite;
      font-weight: bold;
      border-bottom: 6px solid $pdi-sky;
    }
  }
}
.dx-button {
  border-radius: 0;
  box-shadow: none;
  background-image: none;
  background-color: transparent;
    .dx-icon {
    width: 17px;
    height: 19px;
    background-size: 18px 18px;
    padding: 0px 0px 2px 0px;
  }
}
.dx-popup-wrapper {
  .dx-overlay-content {
    overflow: visible !important;
    border: 1px solid $pdi-cool-gray !important;
  }
}
.dx-empty-message {
  border-top: 0 !important;
}
.dx-fileuploader-wrapper {
  padding: 2px;
}
.dx-fileuploader-content > .dx-fileuploader-upload-button {
  margin-left: 3px;
  margin-right: 3px;
}
.dx-fileuploader-input-wrapper {
  display: inline-block !important;
  width: 100% !important;
  padding: 2px 0 2px;
  border: 3px dashed transparent;
}
.dx-fileuploader.dx-state-disabled .dx-fileuploader-input-label {
  position: relative;
}
.dx-fileuploader-dragover .dx-fileuploader-input-wrapper {
  border-color: $pdi-medium-gray;
  padding: 0;
}
.dx-fileuploader-dragover
  .dx-fileuploader-input-wrapper
  .dx-fileuploader-button {
  display: none;
}
.dx-fileuploader-dragover .dx-fileuploader-input-label {
  text-align: center;
}
.dx-fileuploader-dragover .dx-fileuploader-input-container {
  display: inline-block !important;
  display: block;
  width: 100%;
}
.dx-fileuploader-input-container {
  display: inline-flex !important;
}
.dx-fileuploader-dragover .dx-fileuploader-input {
  display: block;
  width: 100%;
  padding: 4px 0;
}
input.dx-fileuploader-input {
  display: none !important;
}
.dx-fileuploader-dragover .dx-fileuploader-input-label {
  padding: 4px 4px;
}
.dx-fileuploader-file-status-message,
.dx-fileuploader-file-size {
  color: $pdi-cool-gray;
}
.dx-fileuploader-file-status-message {
  display: inline-block !important;
  margin: 1rem;
}
.dx-fileuploader-input {
  padding: 2px 0;
}
.dx-fileuploader-input-label {
  padding: 5px 4px;
  color: $pdi-soft-black;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dx-fileuploader-files-container {
  display: none !important; // float: left;
  padding: 12px 3px 0;
}
.dx-fileuploader-files-container .dx-fileuploader-button {
  padding: 0;
  float: left;
}
.dx-fileuploader-file {
  display: none !important;
  // padding-top: 5px; // line-height: 13px;
}
.dx-fileuploader-empty .dx-fileuploader-files-container {
  display: inline-block;
  padding: 0;
}
.dx-fileuploader-file-name {
  padding-bottom: 1px;
  color: $pdi-soft-black;
}
.dx-fileuploader-file-size {
  padding-bottom: 1px;
}
.dx-fileuploader-file-info {
  display: inline-block !important;
  margin-left: 1rem;
}
// latest dx tag box
.dx-texteditor.dx-editor-outlined {
  background: $pdi-white;
  border: 1px solid $pdi-cool-gray;
  border-radius: 4px;
}
.dx-dropdowneditor.dx-dropdowneditor-field-clickable {
  cursor: pointer;
  width: 100%; // custom
  display: inline-block;
  // custom
}
.dx-dropdowneditor-input-wrapper {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow: hidden;
  height: 100%;
}
select[multiple],
select[size] {
  height: auto;
}
.dx-texteditor-container.dx-tag-container {
  white-space: normal;
  display: inline-flex; // custom
  .dx-tagbox.dx-tagbox-default-template.dx-tagbox-only-select
    .dx-texteditor-input {
    border: none;
    color: transparent;
    text-shadow: 0 0 0 $pdi-grey;
    min-width: 0;
    width: 0.1px;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
    .dx-placeholder {
    color: $pdi-grey;
  }
      .dx-placeholder {
    position: absolute;
    top: 0px;
    left: 0px;
    max-width: 100%;
    width: auto;
    height: 100%;
    text-align: left;
    cursor: text;
    pointer-events: none;
  }
}
.dx-tag-container {
  padding: 0;
  padding-right: 4px;
  outline: none;
}
.dx-texteditor-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.container .dx-button-mode-contained
{
  border: none !important;
}
.dx-tag {
  max-width: calc(99%);
  display: inline-block;
    .dx-tag-content {
    position: relative;
    display: inline-block;
    margin: 4px 0 0 4px;
    min-width: 30px;
    text-align: left;
    cursor: pointer;
    .dx-tag-remove-button {
      width: 25px;
      height: 100%;
    }
    .dx-tag-remove-button {
      position: absolute;
      top: 0;
      right: 0;
    }
    .dx-tag-remove-button:before,
    .dx-tag-remove-button:after {
      right: 9px;
      margin-top: -5px;
      width: 3px;
      height: 11px;
      background:$pdi-cool-gray;
    }
    .dx-tag-remove-button:before,
    .dx-tag-remove-button:after {
      position: absolute;
      top: 50%;
      content: "";
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    .dx-tag-remove-button:after {
      right: 5px;
      margin-top: -1px;
      width: 11px;
      height: 3px;
    }
    .dx-widget,
    .dx-widget:before,
    .dx-widget:after,
    .dx-widget *,
    .dx-widget *:before,
    .dx-widget *:after {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
    .dx-tag-remove-button:before {
      right: 9px;
      margin-top: -5px;
      width: 3px;
      height: 11px;
      background: $pdi-cool-gray;
    }
    .dx-tag-remove-button:before,
    .dx-tag-remove-button:after {
      position: absolute;
      top: 50%;
      content: "";
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  .dx-tag-content {
    margin: 4px 0 0 4px;
    padding: 3px 25px 4px 6px;
    min-width: 40px;
    background-color: $pdi-product-background-gray;
    border-radius: 2px;
    color: $pdi-soft-black;
  }
}
// end dx box
.dx-switch {
  .dx-switch-container {
    .dx-switch-inner {
      .dx-switch-handle:before {
        background-color: $pdi-cool-gray;
      }
    }
  }
  &.dx-switch-on-value {
    .dx-switch-handle:before {
      background-color: $pdi-ocean !important;
    }
  }
}
.CodeMirror-hint-active {
  background-color: $pdi-ocean !important;
}
.contentBoxRight > .alert {
  overflow-y: scroll;
  max-height: 100px;
}
.dx-texteditor-container.dx-tag-container {
  display: block;
}
.form-custom-control:focus, .form-control:focus
{
  outline: 0;
  border: 1px solid $pdi-plum !important;
  box-shadow:none !important;
}
.dx-toolbar-label>div
{
  height: 26px !important;
}
.dx-popup-title .dx-closebutton
{
  border:none;
}

.white-space-normal {
  white-space: normal;
}



.dataGridDiv
{
  border : 1px solid $pdi-cool-gray;
}

.cdk-overlay-container {
  z-index: 1053 !important;
}

.dx-overlay-shader {
  background-color:rgba(0,0,0,0.5) !important;
}

.dx-gridbase-container .dx-datagrid-table .dx-row-inserted .dx-editor-cell .dx-texteditor,
.dx-gridbase-container .dx-datagrid-table .dx-edit-row .dx-editor-cell .dx-texteditor
{
  height: unset !important;
  border: none;
}
.overflow-y-auto
{
  overflow-y: auto;
}

.dx-tagbox-popup-wrapper .dx-overlay-content 
{
  min-width: 300px;
}

.childs {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

.childs.showChild {
  max-height: 2000px;
}

.dx-tag .dx-tag-content {
  font-size: 12px !important;
}

.dx-datagrid-header-panel {
  height: 40px;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-lines)>td,
.dx-datagrid-rowsview .dx-selection.dx-row:hover:not(.dx-row-lines)>td {
  border-top: 1px solid $pdi-cool-gray !important;
  border-bottom: 1px solid $pdi-cool-gray !important;
}
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused).dx-column-lines > td, 
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused).dx-column-lines > tr > td,
 .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover.dx-column-lines > td, 
 .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover.dx-column-lines > tr > td
 {
  border-left: 1px solid $pdi-cool-gray !important;
  border-right: 1px solid $pdi-cool-gray !important;
 }
 .dx-datagrid .dx-column-lines > td
 {
   border-right: 1px solid $pdi-cool-gray  !important;
   border-left: 1px solid $pdi-cool-gray !important;
 }
 .dx-datagrid .dx-column-lines > td
 {
   border-bottom: 1px solid $pdi-cool-gray  !important;
 }
.dx-datagrid-rowsview .dx-selection.dx-row>td,
.dx-datagrid-rowsview .dx-selection.dx-row:hover>td {
  background-color: rgba($pdi-sunshine, 0.349) !important;
  border-top: 1px solid $pdi-cool-gray !important;
  border-bottom: 1px solid $pdi-cool-gray !important;
  color: $pdi-granite !important;
}

.dx-gridbase-container .dx-datagrid-table .dx-edit-row .dx-editor-cell .dx-texteditor {
  border: 1px solid $pdi-cool-gray;
}

.dx-toolbar {
  background-color: inherit;
}

.dx-row td[role=columnheader] {
  text-align: center !important;
}
.dx-datagrid-rowsview .dx-selection.dx-row > td a, .dx-datagrid-rowsview .dx-selection.dx-row:hover > td a
{
  color: $pdi-yellow !important;
}
.dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines)>td,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines)>tr:first-child>td {
  border-top: 1px solid $pdi-cool-gray !important;
  border-bottom: 1px solid $pdi-cool-gray !important;
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit:not(.dx-focused) .dx-link
{
  background-color: transparent !important;
}
.dx-datagrid-rowsview .dx-row-focused.dx-data-row>td:not(.dx-focused),
.dx-datagrid-rowsview .dx-row-focused.dx-data-row>tr>td:not(.dx-focused) {
  background-color: rgba($pdi-sunshine, 0.349) !important;
  color: $pdi-granite;
}
.dx-datagrid-rowsview .dx-link-delete, .dx-link-save, .dx-link-cancel, .dx-link-edit
{
  color: $pdi-product-action !important;
}
.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused.dx-list-item-selected {
  background-color: rgba(0, 0, 0, .1) !important;
  color: $pdi-soft-black;
}

.dx-list-item.dx-state-focused,
.dx-list-item.dx-state-active {
  color: inherit !important;
}
.dx-datagrid-rowsview .dx-datagrid-table .dx-row.dx-freespace-row.dx-column-lines{
height: 0px !important;
}
.dx-scrollable-scrollbars-alwaysvisible.dx-scrollable-both > .dx-scrollable-wrapper > .dx-scrollable-container > .dx-scrollable-content, 
.dx-scrollable-scrollbars-alwaysvisible.dx-scrollable-horizontal > .dx-scrollable-wrapper > .dx-scrollable-container > .dx-scrollable-content 
{
  padding-bottom:0px !important;
}
.newGridDiv .dx-datagrid-rowsview .dx-selection>td,
.newGridDiv .dx-datagrid-rowsview .dx-selection.dx-row:hover>td {
  background-color: rgba($pdi-sunshine, 0.349) !important;
  border-top: 1px solid $pdi-cool-gray !important;
  border-bottom: 1px solid $pdi-cool-gray !important;
  color: $pdi-granite !important;
}
.dx-calendar-navigator .dx-button .dx-icon {
  padding: 5px 0px 2px 5px;
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused {
  background-color: revert;
}

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 14px;
  line-height: 1.42857143;
  color: $pdi-soft-black;
  background-color: $pdi-white;
}

/** PDI Library*/
html{
  font-size: 16px;
}

.mb-0{
  margin-bottom: 0 !important;
}

.left-align{
  text-align: left !important;
}

a{
  text-align: left !important;
}

.simple-separator {
  background-color: $pdi-gray;
  height: 1px;
}

.pdi-login-logo-dark {
  background-image: url("assets/Content/Images/Logo/pdi-login-logo.svg");
  background-repeat: no-repeat;
  background-size: contain;
  height: 86px;
  width: 52%;
  align-self: center;
  margin-bottom: 1rem;
}

.navbar-fixed-bottom
{
  margin-bottom: 0px;
}

.pdi-dark-mode pdi-forms-error .pdi-forms-enabled__error-message {
  float: left;
  padding-top: 6px;
}

.pdi-login-form {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding-top: 10px;
}

.pdi-login {
  position: absolute;
  top: 42%;
  left: 50%;
  transform:translate(-50%,-50%);
}

.pdi-login-sso {
  margin: 10px;
  display: grid;
  text-align: center;
  justify-content: center;
}

.pdi-login-sso-form {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding-top: 10px;
}
/** Header styles*/
.pdi-logo
{
  padding: 6px 8px;
  height: 50px;
  float: left;
}
.pdi-header-application-name
{
  font-family: DM sans,sans-serif;
  font-weight: 500;
  font-size: 1.125rem;
  color: $pdi-granite;
  float: left;
  margin-top: 15px;
  margin-left: 5px;
}
.logo-container
{
  float:left;
  min-height: 50px;
}
.headerBoxBottom {
  background-color: $pdi-indigo;
  color: $pdi-base-white;
  height: 20px;
  width:100%;
}
.headerBox {
  position: absolute;
  width: 100%;
}

.menuitem-separator {
  height: 24px;
  width: 2.5px;
  margin: 13px 0 0 0;
  overflow: hidden;
  background-color: $pdi-indigo;
}
.navbar, navbar-default {
  background-color: $pdi-base-white;
}
.navbar-default .navbar-nav > li > a,
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  color: $pdi-indigo;
  background-color: $pdi-base-white;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: $pdi-indigo;
  background-color: $pdi-cool-gray;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  background-color: $pdi-cool-gray;
  color: $pdi-indigo;
}
.navbar-default .dropdown-menu, .navbar-default .open .dropdown-menu  
{ 
  background-color: $pdi-base-white;
}
.dropdown-menu > li > a 
{ 
  color: $pdi-granite;
  cursor: pointer;
}
.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus { 
  color:$pdi-granite; 
  background-color: $pdi-product-background-gray;
  cursor: pointer;
}
.dropdown-menu > li > a:active{ 
  color:$pdi-base-white; 
  background-color: $pdi-product-action;
  cursor: pointer;
}
@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: $pdi-base-white;
    cursor: pointer;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: $pdi-indigo;
    background-color: $pdi-base-white;
    cursor: pointer;
  }
}
/** Header styles Ends*/
/** PDI date picker */

.calendar-clear-button {
text-align: right !important;
}
/**PDI date picker style ends*/
/** PDI Library End*/
.white-bg
{
  background-color: $pdi-white;
}
::placeholder {
  text-align: left !important;
}

.text-center{
  text-align: center !important;
}

.sub-heading{
  font-size: 18px;
}

/* button style*/

button[id^="action-"],
.edit-button-green,
button.edit-button-yellow,
.btn-icon-action {
    background-color: $pdi-ocean;
    color: $pdi-white;
    padding: 6px 20px;
    border-radius: 0.25rem !important;
    border: 1px solid transparent;
}

button[id^="action-"]:hover,
.edit-button-green:hover,
button.edit-button-yellow:hover,
.btn-icon-action:hover {
    background-color: rgba($pdi-ocean, 0.8);
    border-color: $pdi-ocean;
}

button[id^="action-"] span,
.edit-button-green span,
button.edit-button-yellow span {
    letter-spacing: .34px;
    font-size: 0.876rem;
}

button[id^="action-"]:hover span,
.edit-button-green:hover span,
button.edit-button-yellow:hover span {
    text-decoration: underline;
}

.btn-icon-action:disabled {
    background-color: $pdi-cool-gray;
    border-color: $pdi-gray;
}

button[id^="action-"]:disabled {
    background-color: $pdi-product-background-gray;
    color: $pdi-cool-gray;
    border-color: $pdi-cool-gray;
}

button[id^="action-"]:disabled:hover {
    background-color: $pdi-product-background-gray;
    border-color: $pdi-cool-gray;
}

button[id^="action-"]:disabled:hover span {
    text-decoration: none;
}

button[id^="action-"] .edit-button.disabled,
button[id^="action-"] .edit-button-image.disabled {
    background-color: transparent !important;
}

.edit-button-image,
.edit-button,
.add-button-image {
    padding: 0px !important;
}

.add-button {
    padding: 0px 0px 0px 5px !important;
}

.edit-button-image-green {
    background-color: $pdi-ocean;
}

button.edit-button-image {
    background-color: $pdi-ocean;
    color: $pdi-white;
    padding: 6px 6px !important;
}

.btn-icon-action {
    padding: 5px 5px;
}

.dx-icon.dx-icon-close {
    color: $pdi-white;
}

input[type='checkbox'] {
    accent-color: $pdi-ocean;
    width: 18px;
    height: 18px;
}

.dx-checkbox-checked .dx-checkbox-icon {
    color: $pdi-white;
    background-color: $pdi-ocean;
}

.configIdContainer,
.config-id,
.module-id {
    border: 2px solid $pdi-indigo;
    border-radius: 5px 5px;
    padding: 2px 5px;
    color: $pdi-indigo;
    font-weight: bold;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control,
.form-custom-control[disabled],
.form-custom-control[readonly],
fieldset[disabled] .form-custom-control {
    background-color: $pdi-light-gray !important;
    opacity: 0.8;
}

.form-control,
.form-custom-control {
    border: 1px solid $pdi-cool-gray !important;
}

#entity-detail #entity-form select,
.form-control,
.form-custom-control {
    border-radius: 0.25rem !important;
}

.table>tbody>tr.danger>td {
    background-color: $pdi-product-alert-tint;
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    border-top: 1px solid $pdi-cool-gray;
}

.table>tbody>tr.success>td,
.table>tbody>tr.success>th,
.table>tbody>tr>td.success,
.table>tbody>tr>th.success,
.table>tfoot>tr.success>td,
.table>tfoot>tr.success>th,
.table>tfoot>tr>td.success,
.table>tfoot>tr>th.success,
.table>thead>tr.success>td,
.table>thead>tr.success>th,
.table>thead>tr>td.success,
.table>thead>tr>th.success {
    background-color: rgba($pdi-sunshine, 0.349);
}

.arrow-move {
    width: 50px;
}

.arrow-move.active {
    cursor: pointer;
}

.img-icon {
    width: 30px
}

.dx-tab.dx-state-focused::after {
    border: none;
}

.modelstate-invalid {
    background-color: $pdi-product-alert-tint !important;
    border-color: $pdi-alert !important;
}

#config {
    .badge {
        background-color: $pdi-product-dark-slate;
        position: revert;
    }

    .error-alert {
        border: 1px solid $pdi-alert;
    }

    .disabled-tag {
        border-radius: 4px 0px 0px 4px;
        border: 1px solid $pdi-cool-gray;
    }
}

/* button style */
.nsm-dialog-btn-close {
    color: $pdi-white;
}

/**Pdi add/edit icon button style*/
.pdi-delete-button, .pdi-add-button {
  background-color: $pdi-ocean;
  color: $pdi-white;
  font-size: initial;
  padding: 7px 8px !important;
}
.pdi-add-button:hover,.pdi-delete-button:hover {
  background-color: rgba($pdi-ocean, 0.8);
  border-color: $pdi-ocean;
}
.pdi-delete-button:disabled, .pdi-add-button:disabled
{
  background-color: $pdi-cool-gray;
}
.pdi-icon-Add{
  font-size: 18px !important;
}
/**Pdi add/edit icon button style ends*/
/**Pdi links style*/
.pdi-link-btn:hover, .dx-datagrid-rowsview .dx-link-delete:hover, .dx-link-save:hover, .dx-link-cancel:hover, .dx-link-edit.dx-link-edit:hover
{
text-decoration: none !important;
color: $pdi-product-action !important;
}
/**Pdi links style ends*/
/*common style for data grid header title*/
.datagrid-header-title
{
  background-color: $pdi-product-background-gray !important;
  color: $pdi-granite !important;
  font-weight: 600;
  border-bottom: 1px solid $pdi-cool-gray !important;
  height:36px;
  text-align:center;
  padding:7px;
}
/**PDI dropdown styles*/
pdi-dropdown div.pdi-dropdown div.pdi-dropdown__label-container
{
  display: none !important;
}
pdi-dropdown div.pdi-dropdown div.pdi-dropdown__control
{
  margin-top: 0rem !important;
}
pdi-dropdown.modelstate-invalid div.pdi-dropdown__control
{
  border-color:  $pdi-product-alert !important;
}
/**PDI dropdown styles ends*/
/**PDI loader styles*/
.dx-loadpanel-content-wrapper
{
  display: none !important;
}
.dx-loadpanel-content::before{
  height: auto !important;
  max-height: 100% !important;
}
div.dx-loadpanel-content
{
  height: 35% !important;
}
.dx-overlay-wrapper.dx-overlay-shader.dx-loadpanel-wrapper
{
  background-color: rgba(255, 255, 255, 0.5) !important;
}
div.dx-overlay-content.dx-loadpanel-content
{
  background:none !important;
  box-shadow: none !important;
  border: none !important;
}
#entity-list .dx-overlay-wrapper.dx-loadpanel-wrapper {
  background-color: rgba(255, 255, 255, 0.5) !important;
}
/**PDI loader styles ends*/

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-list-item-selected,
.dx-list-item.dx-list-item-selected.dx-state-hover {
    background-color: $pdi-product-background-gray !important;
    color: $pdi-ocean;
    font-weight: 600;
    border-radius: 5px;
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-list-item-selected.dx-state-hover:not(.dx-state-focused) {
    color: $pdi-ocean;
    background-color: $pdi-product-background-gray;
    text-decoration: underline;
}

.dx-list-item.dx-state-hover {
    text-decoration: underline;
}

.dx-list-item {
    padding: .2rem .7rem !important;
}

.dx-dropdowneditor-button.dx-state-active .dx-dropdowneditor-icon,
.dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon {
    background-color: transparent !important;
}

.dx-dropdowneditor.dx-state-active .dx-dropdowneditor-icon,
.dx-dropdowneditor.dx-state-hover:not(.dx-custom-button-hovered) .dx-dropdowneditor-icon {
    background-color: transparent !important;
}
.footerBox
{
  position: absolute;
  width: 100%;
  background-color: $pdi-indigo;
  color: $pdi-base-white;
  font-size: 0.75rem;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
}

.dx-texteditor.dx-state-disabled {
  background-color: $pdi-light-gray;
}

.dx-selectbox-popup-wrapper,
.dx-overlay-content {
  min-width: 250px !important;
}

.dx-scrollview-content {
  padding: 10px;
  font-family: "Hind Siliguri", "sans-" serif;
}

.dx-dropdowneditor-icon::before {
  content: "\e915" !important;
  font-family: 'pdi-icon';
  font-size: 0.67rem;
}

.dx-texteditor.dx-state-focused.dx-editor-outlined {
  border-color: $pdi-plum;
}